// components/Step3.js
import React, { useState } from 'react';
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import { durations, styleProperties } from 'constants/constants';
import { Snackbar, Alert } from '@mui/material';

const Step3 = ({
  selectedDuration,
  setSelectedDuration,
  handleBack,
  // Remova isLoading dos props se não estiver sendo usado no componente pai
  notificationOpen,
  setNotificationOpen,
  errorMessage,
  setErrorMessage,
  countdown,
  videoData,
  videoUrl,
  selectedStyle,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerateClipsWithValidation = async () => {
    setIsLoading(true);
    try {
      const selectedStyleProps = styleProperties[selectedStyle] || {};
      const cleanVideoUrl = videoUrl.split('https://')[1];
      const finalVideoUrl = `https://${cleanVideoUrl}`;
      console.log(finalVideoUrl);
      
      const requestBody = {
        url: finalVideoUrl,
        title: videoData?.title,
        style_properties: {
          captions_animation_type: selectedStyleProps.captions_animation_type || "none",
          wordLevel: selectedStyleProps.wordLevel || false,
        },
      };

      if (selectedDuration !== "Aleatório") {
        const durationRange = selectedDuration.split('-').map(Number);
        const clipLengthRequest = durationRange.reduce((a, b) => a + b) / durationRange.length;
        if (clipLengthRequest !== null) {
          requestBody.clip_length_request = clipLengthRequest;
        }
      }

      console.log("Request body:", requestBody);

      const response = await fetch('https://api.clipes.pro/api/projects/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      console.log("Resposta da API:", data);

      if (!response.ok) {
        throw new Error(data.message || "Erro ao gerar clipes");
      }

      setErrorMessage(null);
      setNotificationOpen(true);
    } catch (error) {
      console.error("Erro ao gerar clipes:", error);
      setErrorMessage(error.message || "Erro ao gerar clipes. Tente novamente.");
      setNotificationOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <VuiBox display="flex" flexDirection="column" alignItems="center" mt={5}>
      <VuiTypography variant="h5" sx={{ color: "#FFFFFF", marginBottom: "20px" }}>
        Escolha a duração dos clipes
      </VuiTypography>

      <VuiBox display="flex" justifyContent="space-between" maxWidth="800px" width="100%" mb={4}>
        {durations.map((duration) => (
          <VuiButton
            key={duration.id}
            sx={{
              backgroundColor: selectedDuration === duration.id ? "#1E90FF" : "#E0E0E0",
              color: selectedDuration === duration.id ? "#FFFFFF" : "#555",
              width: "18%",
              margin: "5px",
              "&:hover": {
                backgroundColor: "#1E90FF",
                color: "#FFFFFF",
              },
            }}
            onClick={() => setSelectedDuration(duration.id)}
          >
            {duration.label}
          </VuiButton>
        ))}
      </VuiBox>

      <VuiBox display="flex" justifyContent="space-between" width="800px" mt={2} sx={{ paddingRight: "16px" }}>
        <VuiButton
          onClick={handleBack}
          color="text"
          variant="text"
          sx={{
            color: "#FFF",
            marginLeft: "20px",
          }}
        >
          Voltar
        </VuiButton>
        <VuiButton
          color="info"
          variant="contained"
          onClick={handleGenerateClipsWithValidation}
          disabled={isLoading}
        >
          {isLoading ? "Criando clipes..." : "Gerar Clipes"}
        </VuiButton>

        <Snackbar
          open={notificationOpen}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={() => setNotificationOpen(false)}
        >
          <Alert
            onClose={() => setNotificationOpen(false)}
            severity={errorMessage ? 'error' : 'success'}
          >
            {errorMessage ? errorMessage : `Clipe criado! Carregando em ${countdown}...`}
          </Alert>
        </Snackbar>
      </VuiBox>
    </VuiBox>
  );
};

export default Step3;
