import Dashboard from "layouts/clip-generator";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ProjectView from "layouts/project-view";
import { IoRocketSharp } from "react-icons/io5";
import { IoIosDocument } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import { IoHome } from "react-icons/io5";
import { FaRegClapperboard } from "react-icons/fa";

import ClapperboardIcon from "../src/examples/Icons/IconClapperboard"

const routes = [
  {
    type: "collapse",
    name: "Gerador de Clipes",
    key: "clip-generator",
    route: "/clip-generator",
    icon: <ClapperboardIcon size="15px" color="inherit" />,
    component: Dashboard,
    noCollapse: true,
    private: true,
    showSideNav: true,
  },
  {
    type: "collapse",
    name: "Visualização de Projeto",
    key: "project-view",
    route: "/project/:id",  // Rota dinâmica
    component: ProjectView,
    noCollapse: true,
    private: true,
    showSideNav: false, 
  },
  // { 
  //   type: "title", 
  //   title: "Account Pages", 
  //   key: "account-pages", 
  //   showSideNav: true 
  // },
  {
    type: "collapse",
    name: "Perfil",
    key: "profile",
    route: "/profile",
    icon: <BsFillPersonFill size="15px" color="inherit" />,
    component: Profile,
    noCollapse: true,
    private: true,
    showSideNav: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <IoIosDocument size="15px" color="inherit" />,
    component: SignIn,
    noCollapse: true,
    showSideNav: false,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    icon: <IoRocketSharp size="15px" color="inherit" />,
    component: SignUp,
    noCollapse: true,
    showSideNav: false,
  },
];

export default routes;
