// constants/constants.js
export const steps = ["Vídeo", "Legendas", "Duração"];

export const styles = [
  { id: 1, label: "Palavra única", gifUrl: "https://app.spikes.studio/static/media/style-one.d1d4d0c75eb76f31f443.gif" },
  { id: 2, label: "Sentença", gifUrl: "https://app.spikes.studio/static/media/style-two.becf36458faec0bc8f8e.gif" },
  { id: 3, label: "Com Fundo", gifUrl: "https://app.spikes.studio/static/media/style-three.2b0b655733c2d2253de8.gif" },
  { id: 4, label: "Animada", gifUrl: "https://app.spikes.studio/static/media/style-four.4cb49b948a7184ca0712.gif" },
];

export const styleProperties = {
  1: { wordLevel: true, captions_animation_type: "none" }, // Palavra única
  2: { wordLevel: false, captions_animation_type: "none" }, // Sentença
  3: { wordLevel: true, captions_animation_type: "blossom" }, // Com Fundo
  4: { wordLevel: false, captions_animation_type: "karaoke" }, // Animada
};

export const durations = [
  { id: "Aleatório", label: "Aleatório" },
  { id: "40-50", label: "40 – 50s" },
  { id: "50-60", label: "50 – 60s" },
  { id: "60-70", label: "60 – 70s" },
  { id: "70-80", label: "70 – 80s" },
  { id: "80-90", label: "80 – 90s" },
];
