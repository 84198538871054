import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgSignIn from "assets/images/signUpImage.png";
import InputMask from "react-input-mask";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function SignUp() {
  const history = useHistory();
  const MySwal = withReactContent(Swal);

  const [rememberMe, setRememberMe] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handlePhoneNumberChange = (e) => {
    const phone = e.target.value;
    setPhoneNumber(phone);
  };

  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/\D/g, '');
  };

  const handleCreateAccount = async () => {
    try {
      setLoading(true);

      await axios.post('https://api.clipes.pro/api/users/create', {
        name,
        email,
        password,
        phoneNumber: `+${formatPhoneNumber(phoneNumber)}`
      });

      // Exibe o SweetAlert2 com auto close timer para sucesso
      MySwal.fire({
        title: 'Conta criada com sucesso!',
        text: 'Você será redirecionado para a página de login.',
        icon: 'success',
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
        willClose: () => {
          history.push("/authentication/sign-in");
        }
      });

    } catch (error) {
      console.error("Erro ao criar a conta: ", error);

      // Verifica se o erro é relacionado ao e-mail ou telefone já em uso
      if (error.response && error.response.data && error.response.data.message) {
        if (error.response.data.message.includes('e-mail já está em uso')) {
          MySwal.fire({
            title: 'Erro',
            text: 'Este e-mail já está em uso. Tente outro e-mail.',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        } else if (error.response.data.message.includes('número de telefone já está em uso')) {
          MySwal.fire({
            title: 'Erro',
            text: 'Este número de telefone já está em uso. Tente outro número.',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        } else {
          MySwal.fire({
            title: 'Erro',
            text: 'Erro ao criar a conta. Tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        }
      } else {
        MySwal.fire({
          title: 'Erro',
          text: 'Erro ao criar a conta. Tente novamente.',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <CoverLayout
      title="Bem-vindo!"
      color="white"
      description="Crie seus cortes gratuitamente."
      image={bgSignIn}
      premotto="INSPIRADO NO FUTURO"
      motto="GERE CLIPES AUTOMATICAMENTE"
      cardContent
    >
      <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
        <VuiBox
          component="form"
          role="form"
          borderRadius="inherit"
          p="45px"
          sx={({ palette: { secondary } }) => ({
            backgroundColor: secondary.focus,
          })}
        >
          <VuiTypography
            color="white"
            fontWeight="bold"
            textAlign="center"
            mb="24px"
            sx={({ typography: { size } }) => ({
              fontSize: size.lg,
            })}
          >
            Insira seus dados abaixo:
          </VuiTypography>
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                Nome
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                placeholder="Nome completo"
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={({ typography: { size } }) => ({
                      fontSize: size.sm,
                      "&::placeholder": {
                        color: "#fff",
                      },
                    })}
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                E-mail
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                type="email"
                placeholder="Seu melhor e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={({ typography: { size } }) => ({
                      fontSize: size.sm,
                      "&::placeholder": {
                        color: "#fff",
                      },
                    })}
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                Senha
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                type="password"
                placeholder="Digite sua senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={({ typography: { size } }) => ({
                      fontSize: size.sm,
                      "&::placeholder": {
                        color: "#fff",
                      },
                    })}
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                Whatsapp
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <InputMask
                mask="+55 (99) 99999-9999"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                disabled={false}
              >
                {(inputProps) => (
                  <VuiInput
                    {...inputProps}
                    placeholder="Insira um telefone válido"
                    sx={({ typography: { size } }) => ({
                      fontSize: size.sm,
                      "&::placeholder": {
                        color: "#fff",
                      },
                    })}
                  />
                )}
              </InputMask>
            </GradientBorder>
          </VuiBox>
          <VuiBox display="flex" alignItems="center">
            <VuiSwitch color="info" checked={rememberMe} onChange={handleSetRememberMe} />
            <VuiTypography
              variant="caption"
              color="white"
              fontWeight="medium"
              onClick={handleSetRememberMe}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;Lembrar-me
            </VuiTypography>
          </VuiBox>
          <VuiBox mt={4} mb={1}>
            <VuiButton color="info" fullWidth id="create-account-button" onClick={handleCreateAccount} disabled={loading}>
              CRIAR CONTA
            </VuiButton>
          </VuiBox>
          <VuiBox mt={3} textAlign="center">
            <VuiTypography variant="button" color="text" fontWeight="regular">
              Já tem uma conta?{" "}
              <VuiTypography
                component={Link}
                to="/authentication/sign-in"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                Fazer login.
              </VuiTypography>
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </GradientBorder>
    </CoverLayout>
  );
}

export default SignUp;
