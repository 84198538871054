// components/Step1.js
import React from 'react';
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import { Box } from '@mui/material';

const Step1 = ({ videoData, handleNext }) => {
  if (!videoData) return null;

  return (
    <VuiBox display="flex" flexDirection="column" alignItems="center" mt={5}>
      <Box
        sx={{
          backgroundColor: "#0F0F0F",
          borderRadius: "8px",
          padding: "20px",
          display: "flex",
          alignItems: "center",
          maxWidth: "700px",
          width: "100%",
        }}
      >
        {videoData.thumbnail && (
          <img
            src={videoData.thumbnail}
            alt="Video Thumbnail"
            style={{
              width: "280px",
              height: "158px",
              borderRadius: "4px",
              marginRight: "20px",
            }}
          />
        )}
        <VuiBox sx={{ width: "100%" }}>
          {videoData.title && (
            <VuiTypography
              variant="h6"
              color="white"
              fontWeight="bold"
              mb={1}
              sx={{
                overflowWrap: "break-word",
                wordWrap: "break-word",
                whiteSpace: "normal",
              }}
            >
              {videoData.title}
            </VuiTypography>
          )}
          {videoData.channel && (
            <VuiTypography
              variant="body2"
              sx={{
                color: "#AAAAAA !important",
                mb: 0.5,
              }}
            >
              {videoData.channel}
            </VuiTypography>
          )}
          {videoData.views && (
            <VuiTypography
              variant="body2"
              sx={{
                color: "#AAAAAA !important",
                mb: 0.5,
              }}
            >
              {videoData.views} visualizações
            </VuiTypography>
          )}
        </VuiBox>
      </Box>
      <VuiBox display="flex" justifyContent="flex-end" width="700px" mt={3}>
        <VuiButton color="info" variant="contained" onClick={handleNext}>
          Próximo
        </VuiButton>
      </VuiBox>
    </VuiBox>
  );
};

export default Step1;
