// components/Step2.js
import React from 'react';
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import { styles } from 'constants/constants';

const Step2 = ({ selectedStyle, setSelectedStyle, handleBack, handleNext }) => (
  <VuiBox display="flex" flexDirection="column" alignItems="center" mt={5}>
    <VuiBox
      display="flex"
      justifyContent="space-between"
      maxWidth="800px"
      width="100%"
      mb={4}
    >
      {styles.map((style) => (
        <VuiBox
          key={style.id}
          sx={{
            width: "22%",
            cursor: "pointer",
            border: selectedStyle === style.id ? "2px solid #1E90FF" : "2px solid transparent",
            borderRadius: "8px",
            overflow: "hidden",
            "&:hover": { borderColor: "#1E90FF" },
          }}
          onClick={() => setSelectedStyle(style.id)}
        >
          <img
            src={style.gifUrl}
            alt={style.label}
            style={{ width: "100%", height: "auto", display: "block" }}
          />
          <VuiTypography
            variant="body2"
            sx={{
              color: "#FFF",
              fontWeight: "400",
              padding: "5px",
              textAlign: "center",
              marginTop: "8px",
            }}
          >
            {style.label}
          </VuiTypography>
        </VuiBox>
      ))}
    </VuiBox>

    <VuiBox display="flex" justifyContent="space-between" width="800px" mt={2} sx={{ paddingRight: "16px" }}>
      <VuiButton
        onClick={handleBack}
        color="text"
        variant="text"
        sx={{
          color: "#FFF",
          marginLeft: "20px",
          backgroundColor: "#313131",
        }}
      >
        Voltar
      </VuiButton>
      <VuiButton color="info" variant="contained" onClick={handleNext} disabled={!selectedStyle}>
        Próximo
      </VuiButton>
    </VuiBox>
  </VuiBox>
);

export default Step2;
