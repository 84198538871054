// hooks/useFetchProjects.js
import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchProjects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const token = localStorage.getItem("idToken");
        const response = await axios.get("https://api.clipes.pro/api/projects", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Ordenar os projetos pela data de criação (mais recentes primeiro)
        const sortedProjects = response.data.sort((a, b) => {
          const dateA = a.createdAt._seconds * 1000 + a.createdAt._nanoseconds / 1000000;
          const dateB = b.createdAt._seconds * 1000 + b.createdAt._nanoseconds / 1000000;
          return dateB - dateA; // Ordem decrescente, mais recentes primeiro
        });

        setProjects(sortedProjects);
      } catch (error) {
        console.error("Erro ao buscar projetos:", error);
      } finally {
        setTimeout(fetchProjects, 10000); // Atualiza a cada 10 segundos
      }
    };

    fetchProjects();
  }, []);

  return projects;
};

export default useFetchProjects;
