// utils/fetchVideoData.js
export const fetchVideoData = async (videoUrl) => {
    const API_KEY = 'AIzaSyAb3ZZM4_sFSIhv8oousnikgTtSOqKAF7s'; // Replace with your actual API key
  
    const videoIdMatch = videoUrl.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    const videoId = videoIdMatch ? videoIdMatch[1] : null;
  
    if (!videoId) {
      console.error("Invalid YouTube URL");
      return null;
    }
  
    const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=snippet,statistics&key=${API_KEY}`;
  
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
  
      if (data.items && data.items.length > 0) {
        const videoData = data.items[0];
        return {
          title: videoData.snippet.title,
          channel: videoData.snippet.channelTitle,
          views: videoData.statistics.viewCount,
          thumbnail: videoData.snippet.thumbnails.high.url,
        };
      } else {
        console.error("No video data found");
        return null;
      }
    } catch (error) {
      console.error("Error fetching video data:", error);
      return null;
    }
  };
  