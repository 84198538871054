import { useState, useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import VuiBox from "components/VuiBox";
import Sidenav from "examples/Sidenav";
import theme from "assets/theme";
import routes from "routes";
import { jwtDecode } from "jwt-decode";

export default function App() {
  const { pathname } = useLocation();
  const [sidenavColor, setSidenavColor] = useState("info");

   // Função para verificar se o token ainda é válido
   const isTokenValid = (token) => {
    try {
      const decodedToken = jwtDecode(token);  // Uso correto da função
      const currentTime = Date.now() / 1000; // Converter para segundos
      return decodedToken.exp > currentTime; // Comparar com o tempo atual
    } catch (error) {
      return false; // Retorna false se não puder decodificar ou se houver um erro
    }
  };

  // Função para verificar se o usuário está autenticado
  const isAuthenticated = () => {
    const token = localStorage.getItem("idToken") || sessionStorage.getItem("idToken");
    
    if (!token) {
      return false;
    }

    return isTokenValid(token);
  };
  
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/authentication/sign-in" />
        )
      }
    />
  );

  // Determina se a rota atual deve exibir a Sidenav
  const shouldShowSidenav = () => {
    // Rotas onde a Sidenav não deve aparecer
    const noSidenavRoutes = ["/authentication/sign-in", "/authentication/sign-up"];
    
    // Retorna true se a rota atual NÃO estiver entre as rotas sem sidenav
    return !noSidenavRoutes.includes(pathname);
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
  
      if (route.route) {
        return route.private ? (
          <PrivateRoute
            exact
            path={route.route}
            component={route.component}
            key={route.key}
          />
        ) : (
          <Route
            exact
            path={route.route}
            component={route.component}
            key={route.key}
          />
        );
      }
  
      return null;
    });

  const configsButton = (
    <VuiBox
      display="none"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="info"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="white"
      sx={{ cursor: "pointer" }}
    >
    </VuiBox>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* Renderiza a Sidenav apenas se a rota atual permitir */}
      {shouldShowSidenav() && (
        <>
          <Sidenav
            color={sidenavColor}
            brand=""
            brandName="CLIPES.PRO"
            routes={routes}
          />
          {configsButton}
        </>
      )}
      <Switch>
        {getRoutes(routes)}
        <Redirect from="*" to="/clip-generator" />
      </Switch>
    </ThemeProvider>
  );
}
