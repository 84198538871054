// components/StepperModal.js
import React from 'react';
import { Modal, Box, Stepper, Step, StepButton } from '@mui/material';
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import Step1 from './Step1.js';
import Step2 from './Step2.js';
import Step3 from './Step3.js';
import { steps } from 'constants/constants';

const StepperModal = ({
  open,
  handleClose,
  activeStep,
  handleStep,
  completed,
  videoData,
  videoUrl, // Adicione esta prop
  handleNext,
  handleBack,
  selectedStyle,
  setSelectedStyle,
  selectedDuration,
  setSelectedDuration,
  handleGenerateClips,
  isLoading,
  notificationOpen,
  setNotificationOpen,
  errorMessage,
  setErrorMessage,
  countdown,
}) => {
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Step1
            videoData={videoData}
            handleNext={handleNext}
          />
        );
      case 1:
        return (
          <Step2
            selectedStyle={selectedStyle}
            setSelectedStyle={setSelectedStyle}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case 2:
        return (
          <Step3
            selectedDuration={selectedDuration}
            setSelectedDuration={setSelectedDuration}
            handleBack={handleBack}
            handleGenerateClips={handleGenerateClips}
            isLoading={isLoading}
            notificationOpen={notificationOpen}
            setNotificationOpen={setNotificationOpen}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            countdown={countdown}
            videoData={videoData}
            videoUrl={videoUrl} // Passe a videoUrl para o Step3
            selectedStyle={selectedStyle}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(5px)",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      }}
    >
      <Box
        sx={{
          bgcolor: "#313131",
          borderRadius: "12px",
          padding: "20px",
          width: "800px",
          outline: "none",
        }}
      >
        <Stepper nonLinear activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label} completed={!!completed[index]}>
              <StepButton
                color="inherit"
                onClick={handleStep(index)}
                sx={{
                  "& .MuiStepLabel-label": {
                    color: activeStep === index ? "white" : "gray",
                  },
                  "& .MuiStepLabel-root .Mui-completed": {
                    color: "success.main"
                  },
                  "& .MuiSvgIcon-root": {
                    color: "success.main",
                  },
                }}
              >
                <VuiTypography
                  color={activeStep === index ? "white" : "gray"}
                  fontWeight={activeStep === index ? "bold" : "regular"}
                >
                  {label}
                </VuiTypography>
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <VuiBox mt={4}>{renderStepContent(activeStep)}</VuiBox>
      </Box>
    </Modal>
  );
};

export default StepperModal;
