import { Card, Stack, Grid, Snackbar, Alert } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import WhiteLightning from 'assets/images/shapes/white-lightning.svg';
import linearGradient from 'assets/theme/functions/linearGradient';
import colors from 'assets/theme/base/colors';
import carProfile from 'assets/images/shapes/car-profile.svg';
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useState, useEffect } from 'react';
import axios from 'axios';
import StarIcon from '@mui/icons-material/Star';
import { useLocation } from 'react-router-dom'; // Importa o hook useLocation

function Overview() {
  const { gradients, info } = colors;
  const { cardContent } = gradients;

  const location = useLocation(); // Usa o hook useLocation para obter a URL atual
  const [showSuccessAlert, setShowSuccessAlert] = useState(false); // Estado para controlar a exibição do alerta de sucesso
  const [showErrorAlert, setShowErrorAlert] = useState(false); // Estado para controlar a exibição do alerta de erro

  // Estados para armazenar os dados do usuário
  const [userData, setUserData] = useState({
    name: '',
    credits: 0,
    planStatus: 'Free'
  });

  // Função para buscar as informações do usuário
  const fetchUserData = async () => {
    const token = localStorage.getItem('idToken'); // Obtém o token do localStorage
    try {
      const response = await axios.get('https://api.clipes.pro/api/users/me', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      // Atualiza o estado com os dados retornados da API
      const { name, credits } = response.data;
      setUserData({
        name,
        credits,
        planStatus: credits > 0 ? 'Paid' : 'Free' // Define o plano com base na quantidade de créditos
      });
    } catch (error) {
      console.error('Erro ao buscar as informações do usuário:', error);
    }
  };

  // useEffect para buscar os dados do usuário quando o componente for montado
  useEffect(() => {
    fetchUserData();

    // Verifica se o parâmetro from_stripe=success ou from_stripe=cancel está presente na URL
    const params = new URLSearchParams(location.search);
    if (params.get('from_stripe') === 'success') {
      setShowSuccessAlert(true);
      setTimeout(() => setShowSuccessAlert(false), 5000); // Oculta o alerta após 5 segundos

      // Cria um link invisível e simula um clique para abrir uma nova guia
      const link = document.createElement('a');
      link.href = 'https://clipes.pro/success';
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (params.get('from_stripe') === 'cancel') {
      setShowErrorAlert(true);
      setTimeout(() => setShowErrorAlert(false), 5000); // Oculta o alerta após 5 segundos
    }
  }, [location.search]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox mt={5} mb={3}>
        <Grid
          container
          spacing={2}
          sx={({ breakpoints }) => ({
            [breakpoints.only("xl")]: {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
          })}
        >
          <Grid
            item
            xs={12}
            xl={5}
            xxl={6}
            sx={({ breakpoints }) => ({
              [breakpoints.only("xl")]: {
                gridArea: "2 / 1 / 3 / 3",
              },
            })}
          >
            <Card
              sx={({ breakpoints }) => ({
                [breakpoints.up('xxl')]: {
                  maxHeight: '400px'
                }
              })}>
              <VuiBox display='flex' flexDirection='column'>
                <VuiTypography variant='lg' color='white' fontWeight='bold' mb='6px'>
                  Informações do seu perfil
                </VuiTypography>
                <VuiTypography variant='button' color='text' fontWeight='regular' mb='30px'>
                  Olá, {userData.name}!
                </VuiTypography>
                <Stack
                  spacing='36px' // Aumentado o espaçamento vertical entre os itens
                  background='#fff'
                  sx={({ breakpoints }) => ({
                    [breakpoints.up('sm')]: {
                      flexDirection: 'column'
                    },
                    [breakpoints.up('md')]: {
                      flexDirection: 'row'
                    },
                    [breakpoints.only('xl')]: {
                      flexDirection: 'column'
                    }
                  })}>

                  <Grid
                    container
                    sx={({ breakpoints }) => ({
                      spacing: '24px',
                      [breakpoints.only('sm')]: {
                        columnGap: '0px',
                        rowGap: '24px'
                      },
                      [breakpoints.up('md')]: {
                        gap: '36px',
                        ml: '50px !important'
                      },
                      [breakpoints.only('xl')]: {
                        gap: '12px',
                        mx: 'auto !important'
                      }
                    })}>
                    <Grid item xs={12} md={5.5} mb={2} xl={5.8} xxl={5.5}>
                      <VuiBox
                        display='flex'
                        p='18px'
                        alignItems='center'
                        sx={{
                          background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
                          minHeight: '110px',
                          borderRadius: '20px'
                        }}>
                        <VuiBox display='flex' flexDirection='column' mr='auto'>
                          <VuiTypography color='text' variant='caption' fontWeight='medium' mb='2px'>
                            Plano Atual
                          </VuiTypography>
                          <VuiTypography
                            color='white'
                            variant='h4'
                            fontWeight='bold'
                            sx={({ breakpoints }) => ({
                              [breakpoints.only('xl')]: {
                                fontSize: '20px'
                              }
                            })}>
                            {userData.planStatus}
                          </VuiTypography>
                        </VuiBox>
                        <VuiBox
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                          sx={{
                            background: info.main,
                            borderRadius: '12px',
                            width: '56px',
                            height: '56px'
                          }}>
                          <StarIcon sx={{ color: 'white', fontSize: 32 }} /> {/* Novo ícone para o plano */}
                        </VuiBox>
                      </VuiBox>
                    </Grid>
                    <Grid item xs={12} md={5.5} xl={5.8} xxl={5.5}>
                      <VuiBox
                        display='flex'
                        p='18px'
                        alignItems='center'
                        sx={{
                          background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
                          borderRadius: '20px',
                          minHeight: '110px'
                        }}>
                        <VuiBox display='flex' flexDirection='column' mr='auto'>
                          <VuiTypography color='text' variant='caption' fontWeight='medium' mb='2px'>
                            Créditos
                          </VuiTypography>
                          <VuiTypography
                            color='white'
                            variant='h4'
                            fontWeight='bold'
                            sx={({ breakpoints }) => ({
                              [breakpoints.only('xl')]: {
                                fontSize: '20px'
                              }
                            })}>
                            {userData.credits}
                          </VuiTypography>
                        </VuiBox>
                        <VuiBox
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                          sx={{
                            background: info.main,
                            borderRadius: '12px',
                            width: '56px',
                            height: '56px'
                          }}>
                          <VuiBox component='img' src={WhiteLightning} />
                        </VuiBox>
                      </VuiBox>
                    </Grid>
                  </Grid>
                </Stack>
              </VuiBox>
            </Card>
          </Grid>
        </Grid>
      </VuiBox>
      <Snackbar
        open={showSuccessAlert}
        autoHideDuration={5000}
        onClose={() => setShowSuccessAlert(false)}
      >
        <Alert onClose={() => setShowSuccessAlert(false)} severity="success" sx={{ width: '100%' }}>
          Plano do usuário ativado com sucesso!
        </Alert>
      </Snackbar>
      <Snackbar
        open={showErrorAlert}
        autoHideDuration={5000}
        onClose={() => setShowErrorAlert(false)}
      >
        <Alert onClose={() => setShowErrorAlert(false)} severity="error" sx={{ width: '100%' }}>
          Falha na ativação do plano do usuário.
        </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default Overview;
