import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { useTheme } from "@mui/material/styles";
import { Card, Box, useMediaQuery, Button, Chip, IconButton, Tooltip  } from "@mui/material";
import Grid from "@mui/material/Grid";
import DownloadIcon from "@mui/icons-material/Download";
import CircularProgress from "@mui/material/CircularProgress";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const ProjectView = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingDownload, setLoadingDownload] = useState({});  // Changed to an object
  const [isFetching, setIsFetching] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchProject = async () => {
    if (isFetching) return;
    setIsFetching(true);
    try {
      const token = localStorage.getItem("idToken");
      if (!token) throw new Error("Token de autenticação não encontrado");

      const response = await axios.get(`https://api.clipes.pro/api/projects/external/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProject(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar o projeto:", error);
      setLoading(false);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchProject();
    const intervalId = setInterval(() => {
      fetchProject();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [id]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (!project) {
    return <div>Projeto não encontrado</div>;
  }

  const handleDownload = (clipId, url, fileName) => {
    if (!url) return;
    setLoadingDownload(prevState => ({ ...prevState, [clipId]: true }));

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName || "downloaded-video.mp4";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => {
      setLoadingDownload(prevState => ({ ...prevState, [clipId]: false }));
    }, 1000);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <VuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        p={3}
        sx={{
          color: "#fff",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <VuiBox
          flex={isMobile ? 1 : "0 0 50%"}
          pr={isMobile ? 0 : 5}
          sx={{ zIndex: 2 }}
        >
          <VuiTypography variant={isMobile ? "h4" : "h3"} color="white" fontWeight="bold" mb={5}>
            {project.title}
          </VuiTypography>
          <VuiTypography color="white" fontWeight="light">
            Nome do Canal: {project.channel_name}
          </VuiTypography>
          <VuiBox display="flex" alignItems="center" mt={1}>
            <VuiBox mr={1}>
              <VuiTypography color="white" fontWeight="light">
                {project.total_views.toLocaleString("en-US")} visualizações
              </VuiTypography>
            </VuiBox>
          </VuiBox>
          <VuiBox mt={1}>
            <VuiTypography color="white" fontWeight="light">
              Duração: {new Date(project.duration * 1000).toISOString().substr(11, 8)}
            </VuiTypography>
          </VuiBox>
          <VuiBox mt={1}>
            <VuiTypography color="white" fontWeight="light">
              Cortes: {project.clips.length}
            </VuiTypography>
          </VuiBox>
        </VuiBox>

        {!isMobile && (
          <VuiBox flex="0 0 50%" display="flex" justifyContent="center">
            <img
              src={project.thumbnail_url}
              alt={project.title}
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "500px",
                borderRadius: "10px",
              }}
            />
          </VuiBox>
        )}
      </VuiBox>

      <VuiBox p={3}>
        <VuiTypography variant="h3" color="white" fontWeight="bold" mb={3}>
          Clipes do Projeto
        </VuiTypography>

        {project.clips.length === 0 ? (
          <VuiTypography variant="body1" color="white">
            Os seus clipes ainda estão sendo processados. Vamos te avisar quando ficarem prontos!
          </VuiTypography>
        ) : (
          <Grid container spacing={2} justifyContent="center">
            {project.clips.map((clip) => {
              const tagsArray = clip.tags.replace(/[\[\]']+/g, "").split(", ");

              return (
                <Grid item xs={12} md={6} xl={4} key={clip.id}>
                  <Card sx={{ backgroundColor: '#1e1e2f', padding: '16px', borderRadius: '12px', color: 'white' }}>
                    <VuiBox display="flex" flexDirection="row">
                      <VuiBox display="flex" flexDirection="column" pl={2} justifyContent="space-between" sx={{ width: '100%' }}>
                        <VuiBox display="flex" alignItems="center" mb={2}>
                          <Box sx={{ position: 'relative', display: 'inline-flex', marginRight: '8px' }}>
                            <CircularProgress
                              variant="determinate"
                              value={clip.clip_ranking}
                              size={40}
                              thickness={5}
                              sx={{ color: '#4CAF50' }}
                            />
                            <Box
                              sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <VuiTypography variant="caption" component="div" color="white">
                                {`${Math.round(clip.clip_ranking)}`}
                              </VuiTypography>
                            </Box>
                          </Box>
                          <VuiTypography
                            variant="h6"
                            color="white"
                            fontWeight="bold"
                            noWrap
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              maxWidth: '100%',
                            }}
                          >
                            {clip.name}
                          </VuiTypography>
                        </VuiBox>

                        <VuiTypography variant="body1" color="light">
                          Duração: <span style={{ color: theme.palette.success.main }}>{clip.duration.toFixed(0)}</span> <span style={{ color: theme.palette.success.main }}>segundos</span>
                        </VuiTypography>

                        {/* Descrição sem truncamento */}
                        <VuiTypography variant="h6">
                          Descrição:
                        </VuiTypography>
                        <VuiBox mt={1} display="flex" alignItems="center" sx={{ backgroundColor: "#2F2F2F", p: 2, borderRadius: "8px" }}>

                          <VuiTypography variant="h6" color="light">
                            {clip.description}
                          </VuiTypography>
                          <Tooltip title="Copiar Descrição">
                            <IconButton
                              onClick={() => copyToClipboard(clip.description)}
                              sx={{ color: "white", ml: 1 }}
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </VuiBox>

                        {/* Tags com cópia */}
                        <VuiTypography variant="h6" color="dark">
                          Tags:
                        </VuiTypography>
                        <VuiBox mt={1} display="flex" alignItems="center" sx={{ backgroundColor: "#2F2F2F", p: 2, borderRadius: "8px" }}>

                          <VuiBox display="flex" flexWrap="wrap">
                            {tagsArray.map((tag) => (
                              <Chip
                                key={tag}
                                label={tag}
                                sx={{ margin: '4px', backgroundColor: '#474747', color: 'white' }}
                              />
                            ))}
                          </VuiBox>
                          <Tooltip title="Copiar Tags">
                            <IconButton
                              onClick={() => copyToClipboard(tagsArray.join(", "))}
                              sx={{ color: "white", ml: 1 }}
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </VuiBox>

                        <VuiBox mt={2} display="flex" justifyContent="space-between">
                          <Button
                            variant="outlined"
                            startIcon={loadingDownload[clip.id] ? <CircularProgress size={20} color="inherit" /> : <DownloadIcon />}
                            sx={{ 
                              color: 'white', 
                              borderColor: 'white', 
                              pointerEvents: loadingDownload[clip.id] || !clip.mobile_download_url ? 'none' : 'auto',
                              opacity: !clip.mobile_download_url ? 0.5 : 1
                            }}
                            onClick={() => handleDownload(clip.id, clip.mobile_download_url, clip.name)}
                          >
                            {loadingDownload[clip.id] ? 'Baixando...' : clip.mobile_download_url ? 'Baixar Clipe' : 'Aguarde...'}
                          </Button>
                        </VuiBox>
                      </VuiBox>
                    </VuiBox>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </VuiBox>

      <Footer />
    </DashboardLayout>
  );
};

export default ProjectView;
