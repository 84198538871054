import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, Box, useMediaQuery, Snackbar, Alert } from "@mui/material";
import Grid from "@mui/material/Grid";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import GradientBorder from "examples/GradientBorder";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import radialGradient from "assets/theme/functions/radialGradient";

import { fetchVideoData } from "utils/fetchVideoData";
import { steps, styleProperties } from "constants/constants";
import StepperModal from "components/StepperModal";
import useFetchProjects from "hooks/useFetchProjects";

function Dashboard() {
  const [videoUrl, setVideoUrl] = useState("");
  const [videoData, setVideoData] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState("75");
  const [isLoading, setIsLoading] = useState(false);
  const isDesktop = useMediaQuery("(min-width:768px)");
  const [loading, setLoading] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [errorMessage, setErrorMessage] = useState(null);
  const history = useHistory();

  const projects = useFetchProjects();

  useEffect(() => {
    if (notificationOpen && !errorMessage) {
      const timer = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);

      if (countdown === 0) {
        history.push("/clip-generator");
      }

      return () => clearInterval(timer);
    }
  }, [countdown, notificationOpen, errorMessage, history]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      return prevActiveStep + 1;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handlePaste = async (e) => {
    const pastedText = (e.clipboardData || window.clipboardData).getData("text");
    setVideoUrl(pastedText);

    try {
      const data = await fetchVideoData(pastedText);
      if (data) {
        setVideoData(data);
        if (isDesktop) setOpen(true);
      } else {
        console.error("Failed to fetch video data.");
      }
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };

  const handleGenerateClips = async () => {
    const selectedStyleProps = styleProperties[selectedStyle];
    const durationRange = selectedDuration.split('-').map(Number);
    const clipLengthRequest = durationRange.reduce((a, b) => a + b) / durationRange.length;

    const cleanVideoUrl = videoUrl.split('https://')[1];
    const finalVideoUrl = `https://${cleanVideoUrl}`;

    console.log(finalVideoUrl);

    const requestBody = {
      url: finalVideoUrl,
      clip_length_request: clipLengthRequest,
      title: videoData.title,
      style_properties: {
        captions_animation_type: selectedStyleProps.captions_animation_type,
        wordLevel: selectedStyleProps.wordLevel,
      },
    };

    setIsLoading(true);

    try {
      const response = await fetch('https://api.clipes.pro/api/projects/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
        },
        body: JSON.stringify(requestBody),
      });

      await response.json();

      setNotificationOpen(true);
      setErrorMessage(null);
    } catch (error) {
      console.error("Error generating clips:", error);
      setErrorMessage("Erro ao gerar clipes. Tente novamente.");
      setNotificationOpen(true);
    } finally {
      setIsLoading(false);
      setCountdown(5);
    }
  };

  const handleGenerateClipsWithoutStepper = async () => {
    if (!videoData) {
      console.error("No video data to generate clips");
      return;
    }

    setLoading(true);

    const cleanVideoUrl = videoUrl.split('https://')[1];
    const finalVideoUrl = `https://${cleanVideoUrl}`;

    const requestBody = {
      url: finalVideoUrl,
      clip_length_request: 70,
      title: videoData.title,
      style_properties: {
        captions_animation_type: "none",
        wordLevel: false,
      },
    };

    try {
      const response = await fetch('https://api.clipes.pro/api/projects/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (!response.ok) {
        if (data.message === "Créditos insuficientes para criar o projeto.") {
          setErrorMessage("Créditos insuficientes. Por favor, recarregue seus créditos.");
          setNotificationOpen(true);
        } else {
          setErrorMessage("Erro ao gerar clipes. Tente novamente.");
          setNotificationOpen(true);
        }
      } else {
        setNotificationOpen(true);
        setErrorMessage(null);
        setCountdown(5);
      }

    } catch (error) {
      console.error("Error generating clips:", error);
      setErrorMessage("Erro ao gerar clipes. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox
        py={3}
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        marginBottom="50px"
        bgcolor="#121212"
        height={projects.length === 0 ? "100vh" : "auto"}
      >
        <Card
          sx={{
            padding: "20px",
            textAlign: "center",
            borderRadius: "12px",
            width: "400px",
            boxShadow: "none !important",
            border: "1px solid hsl(0deg 0% 25.17%)",
          }}
        >
          <VuiTypography variant="h3" color="white" fontWeight="bold" mb={3}>
            Clipes.pro
          </VuiTypography>
          <VuiBox display="flex" flexDirection="column" alignItems="center">
            <GradientBorder
              minWidth="100%"
              padding="1px"
              borderRadius={borders.borderRadius.lg}
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle
              )}
            >
              <VuiInput
                type="text"
                placeholder="Insira o link do vídeo"
                value={videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
                onPaste={handlePaste}
                sx={{
                  color: "#fff"
                }}
              />
            </GradientBorder>
            <VuiButton
              color="info"
              variant="contained"
              fullWidth
              sx={{ mt: 2 }}
              onClick={handleGenerateClipsWithoutStepper}
              disabled={loading}
            >
              {loading ? 'Carregando...' : 'Gerar clipes'}
            </VuiButton>

            <Snackbar
              open={notificationOpen && errorMessage !== null}
              autoHideDuration={6000}
              onClose={() => setNotificationOpen(false)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert
                onClose={() => setNotificationOpen(false)}
                severity="error"
                sx={{ width: '100%' }}
              >
                {errorMessage || "Ocorreu um erro ao processar sua solicitação."}
              </Alert>
            </Snackbar>
          </VuiBox>
        </Card>
      </VuiBox>

      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} xl={9}>
          <Card>
            <VuiBox display="flex" flexDirection="column" height="100%">
              <VuiBox display="flex" flexDirection="column" mb="24px">
                <VuiTypography color="white" variant="lg" fontWeight="bold" mb="6px">
                  Projetos
                </VuiTypography>
                <VuiTypography color="text" variant="button" fontWeight="regular">
                  Veja seus últimos projetos
                </VuiTypography>
              </VuiBox>
              <Grid container spacing={3}>
                {projects.length > 0 ? (
                  projects.map((project) => (
                    <Grid item xs={12} md={6} xl={3} key={project.id}>
                      <DefaultProjectCard
                        image={project.thumbnail_url}
                        label={`${project.total_views} views`}
                        title={project.title}
                        description={project.channel_name}
                        action={{
                          type: "internal",
                          route: `/project/${project.id}`,
                          color: "white",
                          label: "Ver Projeto",
                        }}
                      />
                    </Grid>
                  ))
                ) : (
                  <VuiTypography color="text" variant="button" fontWeight="regular">
                    Nenhum projeto encontrado
                  </VuiTypography>
                )}
              </Grid>
            </VuiBox>
          </Card>
        </Grid>
      </Grid>

      <StepperModal
        open={open}
        handleClose={() => setOpen(false)}
        activeStep={activeStep}
        handleStep={handleStep}
        completed={completed}
        videoData={videoData}
        videoUrl={videoUrl} // Adicione esta linha
        handleNext={handleNext}
        handleBack={handleBack}
        selectedStyle={selectedStyle}
        setSelectedStyle={setSelectedStyle}
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        handleGenerateClips={handleGenerateClips}
        isLoading={isLoading}
        notificationOpen={notificationOpen}
        setNotificationOpen={setNotificationOpen}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        countdown={countdown}
      />

      <Footer />

    </DashboardLayout>
  );
}

export default Dashboard;
